import { HTTP } from "../http-constants";

export default class Graphql {
  constructor(token, query) {
    if (token && query) {
      this.token = token;
      this.query = query.getQuery();
      this.variables = query.getVariables();
    }
  }

  request() {
    return HTTP.post(
      "/graphql",
      {
        query: this._query,
        variables: this._variables,
      },
      {
        headers: { "X-Auth-Token": this._token },
      }
    );
  }

  set token(token) {
    this._token = token;
  }

  set query(query) {
    this._query = query;
  }

  set variables(variables) {
    this._variables = variables;
  }
}
