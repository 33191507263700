import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import jQuery from "jquery";
import InfiniteLoading from "vue-infinite-loading";
import titleMixin from "./utils/titleMixin";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

window.jQuery = window.$ = jQuery;

require("bootstrap-sass");
require("./assets/css/base.css");
require("./assets/css/theme.css");

Vue.use(InfiniteLoading, {
  /* options */
});
Vue.mixin(titleMixin);
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
