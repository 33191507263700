export default class DateUtils {
  constructor(value) {
    if (value) {
      if (value instanceof Date) {
        this.date = value;
      } else {
        this.date = new Date(value);
      }
    }
  }

  getDateFormat() {
    let day = this._date.getDate();
    let month = this._date.getMonth() + 1;

    if (day < 10) {
      day = "0" + day;
    }

    if (month < 10) {
      month = "0" + month;
    }

    return day + "/" + month + "/" + this._date.getFullYear();
  }

  getDateString() {
    let options = {
      month: "long",
      year: "numeric",
      timeZone: "Europe/Paris",
    };

    return this._date.toLocaleDateString("fr-FR", options);
  }

  getDateStringLong() {
    let options = {
      weekday: "long",
      year: "numeric",
      month: "short",
      day: "numeric",
      timeZone: "Europe/Paris",
    };

    return this._date.toLocaleDateString("fr-FR", options);
  }

  set date(date) {
    this._date = date;
  }
}
