import axios from "axios";

let baseURL;

if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  // baseURL = "http://localhost/api/public/";
  baseURL = "http://localhost:8080";
} else {
  baseURL = "https://api.tpl-tlse.org";
  // baseURL = "http://www.nas.local:81/api/public/";
}

export const HTTP = axios.create({
  baseURL: baseURL,
  headers: {
    "Access-Control-Allow-Origin": "*",
  },
});
