import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    title: "TPL",
    version: "v2.4.1",
    description: "Témoignage Public Local",
    token: "",
    userName: "User",
    userUuid: "",
    isAccess: false,
    congregationUuid: "",
    congregationName: "Congregation",
  },
  mutations: {
    setToken(state, newValue) {
      this.state.token = newValue;
    },
    setUserName(state, newValue) {
      this.state.userName = newValue;
    },
    setUserUuid(state, newValue) {
      this.state.userUuid = newValue;
    },
    setAccess(state, newValue) {
      this.state.isAccess = newValue;
    },
    setCongregationName(state, newValue) {
      this.state.congregationName = newValue;
    },
    setCongregationUuid(state, newValue) {
      this.state.congregationUuid = newValue;
    },
  },
  actions: {},
  modules: {},
});
