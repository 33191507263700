<template>
  <div>
    <div class="intro">
      <div class="intro-text">
        <h1 class="intro-title">{{ title }}</h1>
        <div class="line-spacer"></div>
        <p>
          <span>{{ description }}</span>
        </p>
      </div>
    </div>
    <footer>
      <p>{{ title }} {{ version }}<br />All Rights Reserved</p>
    </footer>
  </div>
</template>

<script>
export default {
  name: "Welcome",

  title() {
    return `${this.title}`;
  },

  data() {
    return {
      title: this.$store.state.title,
      description: this.$store.state.description,
      version: this.$store.state.version,
    };
  },
};
</script>
