export default class HourUtils {
  constructor(indice, hour, minute, shift, isEnd) {
    if (isEnd) {
      indice++;
    }

    this._hour = 6;
    this._minute = 0;
    this._shift = 0;

    if (indice > 1) {
      this._hour += hour * (indice - 1);
      this._minute += minute * (indice - 1);
    }

    if (shift > 0 && shift <= 120) {
      this._shift += shift;
    }

    this._minute = this._minute + this._shift;

    if (this._minute >= 60) {
      this._hour += parseInt(this._minute / 60);
      this._minute = this._minute % 60;
    }
  }

  get getTitle() {
    return this.hourToString + ":" + this.minuteToString;
  }

  get hour() {
    return this._hour;
  }

  get hourToString() {
    let value = this._hour;

    if (this._hour < 10) {
      value = "0" + this._hour;
    }

    return value;
  }

  get minute() {
    return this._minute;
  }

  get minuteToString() {
    let value = this._minute;

    if (this._minute < 10) {
      value = "0" + this._minute;
    }

    return value;
  }
}
