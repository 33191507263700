<template>
  <div>
    <div class="intro">
      <div class="intro-text">
        <h1 class="intro-title">{{ title }}</h1>
        <div class="line-spacer"></div>
        <p>
          <span>{{ description }}</span>
        </p>
        <div class="message" v-if="isSpinner">
          <div
            class="circle-loader"
            v-bind:class="[isCheckmark ? 'load-complete' : '']"
          >
            <div class="checkmark draw" v-show="isCheckmark"></div>
          </div>
        </div>
        <div class="message" v-else>
          <p><span>Connexion impossible</span></p>
        </div>
      </div>
    </div>
    <footer>
      <p>{{ title }} {{ version }}<br />All Rights Reserved</p>
    </footer>
  </div>
</template>

<script>
import { HTTP } from "../http-constants";

export default {
  name: "Connection",

  data() {
    return {
      isSpinner: true,
      isCheckmark: false,
      token: this.$route.params.token,
      title: this.$store.state.title,
      description: this.$store.state.description,
      version: this.$store.state.version,
    };
  },

  mounted: function () {
    setTimeout(() => {
      this.getProclamateur();
    }, 1000);
  },

  methods: {
    getProclamateur: function () {
      HTTP.post(
        "/auth-clis",
        { token: this.token },
        { headers: { "X-Auth-Token": this.token } }
      )
        .then((response) => {
          // Token
          this.$store.commit("setToken", response.data.token);

          // Username
          this.$store.commit("setUserName", response.data.name);

          // User UUID
          this.$store.commit("setUserUuid", response.data.uuid);

          // User access
          this.$store.commit("setAccess", response.data.droit);

          // Congregation name
          this.$store.commit(
            "setCongregationName",
            response.data.congregationName
          );

          // Congregation UUID
          this.$store.commit(
            "setCongregationUuid",
            response.data.congregationUuid
          );

          this.isCheckmark = true;

          setTimeout(() => {
            let route = "Invitation";

            if (this.$route.path.indexOf("disponibilite") > -1) {
              route = "Disponibilite";
            } else if (this.$route.path.indexOf("rapport") > -1) {
              route = "Rapport";
            }

            this.$router.push({
              name: route,
            });
          }, 1000);
        })
        .catch(() => {
          this.isSpinner = false;
        });
    },
  },
};
</script>
