import HourUtils from "../utils/hourUtils";
import DateUtils from "../utils/dateUtils";

export default {
  methods: {
    accept: function () {
      this.isClicked = true;
      this.isSave = true;
      this.$emit("onSave", this, this.envelope.uuid, 1);
    },

    refuse: function () {
      this.isClicked = true;
      this.isDelete = true;
      this.$emit("onSave", this, this.envelope.uuid, 2);
    },

    clear: function () {
      this.isClicked = false;
      this.isSave = false;
      this.isDelete = false;
    },

    getCreneauTitle: function () {
      return this.getCreneau(false) + " - " + this.getCreneau(true);
    },

    getCreneau: function (isEnd) {
      const hourUtils = new HourUtils(
        this.envelope.creneau,
        this.envelope.hour,
        this.envelope.minute,
        this.envelope.shift,
        isEnd
      );

      return hourUtils.getTitle;
    },

    getDate: function () {
      return new DateUtils(this.envelope.date).getDateStringLong();
    },
  },
};
