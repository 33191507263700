export default {
  methods: {
    isShowPlace: function (place, nextPlace) {
      let isShow = false;

      if (place !== nextPlace) {
        isShow = true;
      }

      return isShow;
    },

    setMutableVariables: function (uuid, answer) {
      let variables = {};
      variables["id"] = "envelopes/" + uuid;
      variables["response"] = answer;

      return this.getMutableContainer(variables, uuid);
    },
  },
};
