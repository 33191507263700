import Vue from "vue";
import VueRouter from "vue-router";
import Welcome from "../views/Welcome.vue";
import Connection from "../views/Connection.vue";
import Invitation from "../views/Invitation.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Welcome",
    component: Welcome,
  },
  {
    path: "/auth/:token",
    name: "Connection",
    component: Connection,
    children: [
      {
        path: "disponibilite",
        name: "Connection|Disponibilite",
        component: Connection,
      },
      {
        path: "rapport",
        name: "Connection|Rapport",
        component: Connection,
      },
    ],
  },
  {
    path: "/invitation",
    name: "Invitation",
    component: Invitation,
  },
  {
    path: "/historique",
    name: "Historique",
    component: () =>
      import(/* webpackChunkName: "historique" */ "../views/Historique.vue"),
  },
  {
    path: "/disponibilite",
    name: "Disponibilite",
    component: () =>
      import(
        /* webpackChunkName: "disponibilite" */
        "../views/Disponibilite.vue"
      ),
  },
  {
    path: "/absence",
    name: "Absence",
    component: () =>
      import(/* webpackChunkName: "absence" */ "../views/Absence.vue"),
  },
  {
    path: "/proclamateurs",
    name: "Proclamateur",
    component: () =>
      import(
        /* webpackChunkName: "proclamateurs" */
        "../views/Proclamateur.vue"
      ),
  },
  {
    path: "/rapport",
    name: "Rapport",
    component: () =>
      import(/* webpackChunkName: "rapport" */ "../views/Rapport.vue"),
  },
  {
    path: "/404",
    name: "404",
    component: () => import(/* webpackChunkName: "404" */ "../views/404.vue"),
  },
  {
    path: "*",
    redirect: "/404",
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
