export default {
  methods: {
    getQueryVariables: function (name, query, uuid, cursor) {
      let variables = {};

      variables[name] = query + uuid;

      if (cursor !== "") {
        variables["cursor"] = cursor;
      }

      return variables;
    },

    getMutableContainer: function (variables, uuid) {
      variables.clientMutationId = uuid;

      return {
        entity: variables,
      };
    },

    getDeleteContainer: function (variables, uuid) {
      variables.clientMutationId = uuid;

      return {
        entity: variables,
      };
    },
  },
};
