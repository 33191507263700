export default class QueryUtils {
  constructor(queries) {
    if (queries) {
      this.query = queries;
    }
  }

  set query(query) {
    this._query = query;
  }

  setVariables(variables) {
    this._variables = variables;
  }

  setRead() {
    this._index = 0;
  }

  setUpdate() {
    this._index = 1;
  }

  setDelete() {
    this._index = 2;
  }

  getQuery() {
    return this._query[this._index];
  }

  getVariables() {
    return this._variables;
  }
}
