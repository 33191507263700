<template>
  <div id="app">
    <transition name="fade-app">
      <div class="container" v-if="isConnection">
        <nav class="navbar navbar-default navbar-fixed-top">
          <div class="navbar-header">
            <button
              type="button"
              class="navbar-toggle collapsed"
              data-toggle="collapse"
              data-target="#navbar"
              aria-expanded="false"
              aria-controls="navbar"
              ref="buttonNavBar"
            >
              <span class="sr-only">Toggle navigation</span>
              <span class="icon-bar"></span>
              <span class="icon-bar"></span>
              <span class="icon-bar"></span>
            </button>
            <div class="navbar-brand">{{ title }} - {{ userName }}</div>
          </div>
          <div id="navbar" class="navbar-collapse collapse">
            <ul class="nav navbar-nav">
              <li
                class="dropdown"
                v-bind:class="[isInvitation ? 'active' : '']"
              >
                <a
                  href="#"
                  class="dropdown-toggle"
                  data-toggle="dropdown"
                  role="button"
                  aria-haspopup="true"
                  aria-expanded="false"
                  >Invitations <span class="caret"></span>
                </a>
                <ul class="dropdown-menu">
                  <li v-bind:class="[page === 'Invitation' ? 'active' : '']">
                    <router-link to="/invitation" class="nav-link"
                      >En attentes
                    </router-link>
                  </li>
                  <li v-bind:class="[page === 'Historique' ? 'active' : '']">
                    <router-link to="/historique" class="nav-link"
                      >Historiques
                    </router-link>
                  </li>
                </ul>
              </li>
              <li
                class="dropdown"
                v-bind:class="[isParametrage ? 'active' : '']"
              >
                <a
                  href="#"
                  class="dropdown-toggle"
                  data-toggle="dropdown"
                  role="button"
                  aria-haspopup="true"
                  aria-expanded="false"
                  >Paramètrages <span class="caret"></span>
                </a>
                <ul class="dropdown-menu">
                  <li v-bind:class="[page === 'Disponibilite' ? 'active' : '']">
                    <router-link to="/disponibilite" class="nav-link"
                      >Disponibilités
                    </router-link>
                  </li>
                  <li v-bind:class="[page === 'Absence' ? 'active' : '']">
                    <router-link to="/absence" class="nav-link"
                      >Absences
                    </router-link>
                  </li>
                  <li
                    v-bind:class="[page === 'Proclamateur' ? 'active' : '']"
                    v-if="isAccess"
                  >
                    <router-link to="/proclamateurs" class="nav-link"
                      >Liste des invitations
                    </router-link>
                  </li>
                </ul>
              </li>
              <li v-bind:class="[page === 'Rapport' ? 'active' : '']">
                <router-link to="/rapport" class="nav-link"
                  >Rapports
                </router-link>
              </li>
            </ul>
          </div>
        </nav>
      </div>
    </transition>
    <transition :name="transitionName" mode="out-in">
      <router-view />
    </transition>
    <transition name="fade-app">
      <div v-if="isConnection">
        <div class="footer">
          <p>{{ title }} {{ version }} - All Rights Reserved</p>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: "App",

  data() {
    return {
      isConnection: false,
      isInvitation: false,
      isParametrage: false,
      isAccess: false,
      transitionName: "slide-fade",
      page: "",
      userName: "",
      title: this.$store.state.title,
      version: this.$store.state.version,
    };
  },

  watch: {
    $route(to, from) {
      this.transitionName = from.name === "Connection" ? "slide-fade" : "fade";
      this.page = to.name;

      if (this.isConnection) {
        this.isInvitation = false;
        this.isParametrage = false;

        if (to.name === "Invitation" || to.name === "Historique") {
          this.isInvitation = true;
        } else if (
          to.name === "Disponibilite" ||
          to.name === "Absence" ||
          to.name === "Proclamateur"
        ) {
          this.isParametrage = true;
        }

        let allElements = Array.from(
          this.$el.querySelectorAll(".dropdown-menu")
        );

        for (const element of allElements) {
          element.classList.remove("show");
        }

        if (typeof this.$refs.buttonNavBar !== "undefined") {
          if (
            this.$refs.buttonNavBar.getAttribute("aria-expanded") === "true"
          ) {
            this.$refs.buttonNavBar.click();
          }
        }
      } else {
        if (
          from.name === "Connection" ||
          from.name === "Connection|Disponibilite" ||
          from.name === "Connection|Rapport"
        ) {
          this.isConnection = true;
        }
      }
    },
    "$store.state.userName": function () {
      this.userName = this.$store.state.userName;
      this.isAccess = this.$store.state.isAccess;
    },
  },
};
</script>

<style lang="scss">
@import "src/assets/scss/design.scss";

/* Transition list */
.list-complete-item {
  transition: all 0.5s;
  display: block;
}

.list-complete-leave-active {
  opacity: 0;
  transform: scale(0);
}

.list-complete-enter-active {
  opacity: 0;
}

/* Transition fade app */
.fade-app-enter-active,
.fade-app-leave-active {
  transition: opacity 1.2s;
}

.fade-app-enter,
.fade-app-leave-to {
  opacity: 0;
}

/* Transition fade */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

/* Transition slide */
.slide-fade-leave-active {
  transition: all 0.9s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-leave-to {
  transform: translateX(-100vh);
  opacity: 0;
}

.slide-fade-enter-active {
  transition: opacity 0.5s;
}

.slide-fade-enter {
  opacity: 0;
}

/* Spinner */
$brand-success: white;
$loader-size: 7em;
$check-height: $loader-size / 2;
$check-width: $check-height / 2;
$check-left: ($loader-size / 6 + $loader-size / 12);
$check-thickness: 3px;
$check-color: $brand-success;

.circle-loader {
  margin-bottom: $loader-size / 2;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-left-color: $check-color;
  animation: loader-spin 1.2s infinite linear;
  position: relative;
  display: inline-block;
  vertical-align: top;
  border-radius: 50%;
  width: $loader-size;
  height: $loader-size;
}

.load-complete {
  -webkit-animation: none;
  animation: none;
  border-color: $check-color;
  transition: border 500ms ease-out;
}

.checkmark {
  &.draw:after {
    animation-duration: 800ms;
    animation-timing-function: ease;
    animation-name: checkmark;
    transform: scaleX(-1) rotate(135deg);
  }

  &:after {
    opacity: 1;
    height: $check-height;
    width: $check-width;
    transform-origin: left top;
    border-right: $check-thickness solid $check-color;
    border-top: $check-thickness solid $check-color;
    content: "";
    left: $check-left;
    top: $check-height;
    position: absolute;
  }
}

@keyframes loader-spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes checkmark {
  0% {
    height: 0;
    width: 0;
    opacity: 1;
  }
  20% {
    height: 0;
    width: $check-width;
    opacity: 1;
  }
  40% {
    height: $check-height;
    width: $check-width;
    opacity: 1;
  }
  100% {
    height: $check-height;
    width: $check-width;
    opacity: 1;
  }
}
</style>
